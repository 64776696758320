import { FC } from "react";
import AuthPageShell from "components/Login/AuthPageShell";
import EmailPasswordForm, {
  FormDataType,
} from "components/Login/EmailPasswordForm";
import LoginRedirect from "components/Login/LoginRedirect";
import PracticeLogo from "components/Login/PracticeLogo";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import analytics from "@lib/analytics";

import { useAuthErrorState } from "@components/Client/SignUpUnifiedOnboardingForm";
import ResetPopup from "@components/Login/ResetPopup";

const LoginPage: FC = () => {
  const router = useRouter();
  const requestedPage = (router.query?.requestedPage as string) || "";

  const [errorMessage, setErrorMessage] = useAuthErrorState();

  const { signin } = useAuth();

  const onSubmit = async (data: FormDataType) => {
    try {
      analytics.track({
        event: "login_click",
        properties: { method: "web", kind: "password" },
      });

      const { email, password } = data;

      await signin(email.toLowerCase().trim(), password);

      analytics.track({
        event: "login",
        properties: { method: "web", kind: "password" },
      });
    } catch (e) {
      if (e.code === "auth/wrong-password") {
        setErrorMessage("You entered a wrong password. Please try again.");
      }
      if (e.code === "auth/user-not-found") {
        setErrorMessage(
          "User not found. Please create an account if you have not already."
        );
      }

      analytics.track({
        event: "login_error",
        properties: { method: "web", kind: "password", error: e.message },
      });
    }
  };

  const continueWithGoogle = async () => {
    analytics.track({
      event: "login_click",
      properties: { method: "web", kind: "google" },
    });

    window.location.href = `/api/gauth/auth-init?view=login&scopes=authorize`;
  };

  return (
    <LoginRedirect requestedPage={requestedPage}>
      <AuthPageShell
        metaTitle="Login"
        title={
          <>
            Sign in to your <span className="text-accent">Practice</span>
          </>
        }
        aboveTitle={<PracticeLogo />}
        content={
          <>
            <ResetPopup />
            <EmailPasswordForm
              defaultValues={{
                email: "",
                password: "",
              }}
              onSubmit={onSubmit}
              onSubmitButtonText="Sign in"
              onGoogleSubmit={continueWithGoogle}
              errorMessage={errorMessage}
              resetPasswordHref="/reset-password"
            />
          </>
        }
      />
    </LoginRedirect>
  );
};

export default LoginPage;
